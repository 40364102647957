@import '../../../../scss/theme-bootstrap';

.product-grid {
  min-height: 660px;
  .mixitup-initialized {
    .product-grid__item {
      display: none;
    }
  }
  &.mixitup-init-product-grid {
    @include breakpoint($medium-up) {
      min-height: auto;
    }
  }
  &--carousel {
    position: relative;
    padding-bottom: 30px;
    @include breakpoint($medium-up) {
      padding-bottom: 0;
    }
    .product-carousel {
      .slick-list {
        width: 100%;
      }
      .slick-track {
        display: flex;
        width: 100%;
      }
    }
    .carousel-controls {
      .slick-prev,
      .slick-next {
        top: calc(50vw + 20px);
        width: 40px;
        height: 40px;
        background: $color-black;
        white-space: normal;
        @include breakpoint($medium-up) {
          @include transform(translateY(-30%));
          top: 30%;
          width: 80px;
          height: 80px;
        }
        &:before {
          content: '';
          position: relative;
          #{$ldirection}: 0;
          width: 100%;
          height: 14px;
          filter: brightness(1) invert(1);
        }
        &.slick-disabled {
          visibility: hidden;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
        @include breakpoint($medium-up) {
          #{$ldirection}: -20px;
        }
        &:before {
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
          transform: rotate(90deg);
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        @include breakpoint($medium-up) {
          #{$rdirection}: -20px;
        }
        &:before {
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
          transform: rotate(-90deg);
        }
      }
    }
    .carousel-dots {
      @include breakpoint($medium-up) {
        display: none;
      }
      .slick-dots {
        position: relative;
        bottom: 0;
        li {
          width: auto;
          height: auto;
          margin: 0;
        }
        button {
          @include swap_direction(margin, 1px 8px 0 0);
          display: inline-block;
          width: 12px;
          height: 1px;
          padding: 0;
          background-color: $color-gray-op65;
          &:before {
            display: none;
          }
          .dark-background & {
            background-color: $color-white-op50;
          }
        }
        .slick-active {
          button {
            height: 2px;
            margin-top: 0;
            background-color: $color-core-black;
            .dark-background & {
              background-color: $color-white;
            }
          }
        }
      }
    }
  }
  &__wrapper {
    @include pie-clearfix;
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
  }
  &__item {
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;
    height: auto;
    overflow: visible;
    > .block {
      width: 100%;
    }
    @include breakpoint($medium-up) {
      width: 50%;
      &--colspan-2 {
        width: 100%;
      }
      &--colspan-3 {
        width: 100%;
      }
      &--colspan-4 {
        width: 100%;
      }
    }
    @include breakpoint($large-up) {
      width: 25%;
      &--colspan-2 {
        width: 50%;
      }
      &--colspan-3 {
        width: 75%;
      }
      &--colspan-4 {
        width: 100%;
      }
    }
  }
  .mpp-container--product-brief-short & {
    &__item {
      margin: 0 20px;
      padding: 20px 0;
      border-top: 1px solid $color-line;
      @include breakpoint($medium-up) {
        margin: 0;
        padding: 0 20px 60px;
        border-top: none;
      }
      &:first-of-type,
      &.slick-slide,
      &.product-grid__item--promo {
        border-top: none;
      }
    }
  }
}

.mpp-container {
  &__controls {
    position: relative;
    overflow: visible;
    background-color: $color-white;
    display: flex;
    width: 100%;
    z-index: 900;
    @include breakpoint($medium-up) {
      height: 79px;
      padding: 0 30px 39px;
      display: block;
      z-index: 2;
    }
    &--filter {
      @include swap_direction(margin, 0 2% 0 4%);
      float: $ldirection;
      @include breakpoint($medium-up) {
        min-width: 200px;
        width: auto;
        margin: 0;
      }
      @include breakpoint($large-up) {
        max-width: 60%;
      }
      @include breakpoint($xlarge-up) {
        max-width: 70%;
      }
      @include breakpoint($xxlarge-up) {
        max-width: 75%;
      }
      @include breakpoint($xxxlarge-up) {
        max-width: 80%;
      }
    }
    &--sort {
      float: $rdirection;
      position: relative;
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    &.sticky {
      position: fixed;
      top: 54px;
      z-index: 901;
      @include breakpoint($medium-up) {
        z-index: 900;
      }
      .mpp-container {
        &__controls {
          &--sort,
          &--filter {
            margin: 0;
          }
        }
      }
    }
    &.full-page-takeover {
      z-index: 995;
    }
  }
  &__content {
    @include pie-clearfix;
    margin: 0 auto;
    max-width: $max-width-large;
    position: relative;
    z-index: auto;
    background-color: $color-white;
    margin-top: 40px;
  }
}
